<template>
    <div class="content-box">
        <div v-loading="loading" class="mx-auto login p-20 box-shadow">
            <div class="header text-center mb-25">
                <h2 class="mb-0">{{ $t('forget.title') }}</h2>
            </div>
            <el-divider />
            <el-form ref="ruleForm" :model="formData" :rules="rules">
                <el-form-item prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-user" :placeholder="$t('login.email')" />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" :placeholder="$t('login.password')" show-password />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.re_password" prefix-icon="el-icon-unlock" :placeholder="$t('register.re_password')" show-password />
                </el-form-item>
                <el-form-item prop="email_captcha">
                    <el-input v-model="formData.email_captcha" prefix-icon="el-icon-s-check" :placeholder="$t('register.email_captcha')">
                        <template slot="append">
                            <el-button v-if="sendEmailTimeout <= 0" plain type="success" native-type="button" @click="sendEmailCaptcha">{{ $t('register.send_to_email') }}</el-button>
                            <el-button v-else plain type="success" disabled native-type="button">Retry in {{ sendEmailTimeout }}s</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-divider />
            <div class="mt-30 mx-20">
                <el-button class="w-100" type="primary" @click="submitForm">{{ $t('forget.retrieve_now') }}</el-button>
            </div>
            <div class="mt-30 mb-20 text-center font-size-14">
                {{ $t('register.already_have_an_account') }}
                <el-link class="color-primary is-link" @click="$nav.replace('login')">{{ $t('register.sign_in') }}</el-link>
            </div>
        </div>
    </div>
</template>

<script>
import { validEmail } from '@/services/validate'
export default {
    data() {
        return {
            loading: false,
            captchaSrc: null,
            formData: {
                email: this.$store.state.userEmail,
                password: '',
                captcha: ''
            },
            timer: null,
            sendEmailTimeout: 0,
            rules: {
                email: [
                    { required: true, type: 'email', message: 'Please enter Email address', trigger: 'blur' }
                ],
                password: [
                    { required: true, type: 'string', min: 6, message: 'More than 6 characters', trigger: 'blur' }
                ],
                email_captcha: [
                    { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
                ]
            }
        }
    },
    beforeDestroy: function () {
        if(this.timer === null) return
        window.clearInterval(this.timer)
    },
    methods: {
        sendEmailCaptcha: function (){
            if(validEmail(this.formData.email)){
                this.loading = true
                this.$request.post('forget/email_captcha',this.formData).then(()=>{
                    this.loading = false
                    if (this.timer !== null) {
                        window.clearInterval(this.timer)
                    }
                    this.sendEmailTimeout = 120
                    this.timer = window.setInterval(() => {
                        this.sendEmailTimeout--
                        if(this.sendEmailTimeout <= 0){
                            this.timer = null
                        }
                    }, 1000)
                }).catch(() => {
                    this.loading = false
                })
            }else{
                this.$message({
                    message: 'Please Enten',
                    type: 'error'
                })
            }
        },
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                if(this.formData.password != this.formData.re_password){
                    this.$message({
                        message: 'The two passwords entered are inconsistent',
                        type: 'error'
                    })
                    return
                }
                this.loading = true
                this.$request.post('forget', this.formData).then(() => {
                    this.$store.commit('userEmail', this.formData.email)
                    this.loading = false
                    this.$nav.replace('login')
                }).catch(() => {
                    this.formData.captcha = ''
                    this.captchaSrc = null
                    this.loading = false
                })
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        }
  }
}
</script>
<style lang="scss" scoped>
.login{
    width: 400px;
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: white;
    color: #333;
    border-radius: 8px;
}
</style>